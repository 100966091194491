<template>
  <div>
    <div class="main-loading-header">
      <OsiguLogo class="main-loading-header__logo" />
    </div>
    <div class="main-loading">
      <Loading />
    </div>
  </div>
</template>

<script>
  import OsiguLogo from '@/assets/images/svg/OsiguLogo';
  import Loading from '@/components/loading/Loading.vue';

  import ehrApiService from '@/services/ehr-api.service';
  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');
  const authModule = createNamespacedHelpers('auth');

  export default {
    components: {
      OsiguLogo,
      Loading
    },
    async mounted () {
      const UUID = this.$route.params.UUID;
      if (!this.prescription) {
        this.requestClientToken();
        this.setPrescriptionExposureId(UUID);

        const prescription = await ehrApiService.getPrescription(UUID);
        this.setPrescription(prescription)
        this.$router.replace('/');
      } else {
        this.$router.replace('/');
      }
    },
    computed: {
      ...prescriptionModule.mapGetters(['prescription'])
    },
    methods: {
      ...prescriptionModule.mapActions(['setPrescriptionExposureId', 'setPrescription']),
      ...authModule.mapActions(['requestClientToken']),
    }
  };
</script>

<style lang="scss">
  .main-loading-header {
    background-color: #f9f9f9 !important;
    
    &__logo {
      margin-left: 10%;
      height: 100px;
      width: 100px;
    }

  }

  .main-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
  }
</style>
