export default {
  prescription: (state) => state.prescription,
  prescriptionExposureId: (state) => state.prescriptionExposureId,
  products: (state) => state.products,
  selectedProduct: (state) => state.selectedProduct,
  providers: (state) => state.providers,
  provider: (state) => state.provider,
  deliveryCities: (state) => state.deliveryCities,
  paymentMethods: (state) => state.paymentMethods,
  forms: (state) => state.forms,
  createPrescriptionDeliveryLogError: (state) => state.createPrescriptionDeliveryLogError,
  prescriptionDeliveryLog: (state) => state.prescriptionDeliveryLog,
  requestedOrderMessage: (state) => state.requestedOrderMessage,
  nonAssociatedProducts: (state) => state.nonAssociatedProducts,
  error: (state) => state.error,
  formDataCollected: (state) => state.formDataCollected
}
