export const SET_TOKEN = 'SET_TOKEN'
export const CLIENT_TOKEN_SUCCESS = 'CLIENT_TOKEN_SUCCESS'
export const CLIENT_TOKEN_ERROR = 'CLIENT_TOKEN_ERROR'
export const SET_PRESCRIPTION = 'SET_PRESCRIPTION'
export const SET_PRESCRIPTION_EXPOSURE_ID = 'SET_PRESCRIPTION_EXPOSURE_ID'
export const SET_PRESCRIPTION_PRODUCTS = 'SET_PRESCRIPTION_PRODUCTS'
export const SET_PRESCRIPTION_PROVIDERS = 'SET_PRESCRIPTION_PROVIDERS'
export const SET_PRESCRIPTION_CURRENT_PROVIDER = 'SET_CURRENT_PROVIDER'
export const SET_PRESCRIPTION_PROVIDER_DELIVERY_CITIES = 'SET_PRESCRIPTION_PROVIDER_DELIVERY_CITIES'
export const SET_PRESCRIPTION_PROVIDER_PAYMENT_METHODS = 'SET_PRESCRIPTION_PROVIDER_PAYMENT_METHODS'
export const SET_PRESCRIPTION_PROVIDER_FORMS = 'SET_PRESCRIPTION_PROVIDER_FORMS';
export const CREATE_PRESCRIPTION_DELIVERY_LOG = 'CREATE_PRESCRIPTION_DELIVERY_LOG';
export const CREATE_PRESCRIPTION_DELIVERY_LOG_ERROR = 'CREATE_PRESCRIPTION_DELIVERY_LOG_ERROR'
export const SET_REQUESTED_ORDER_MESSAGE = 'SET_REQUESTED_ORDER_MESSAGE'
export const CREATE_NON_ASSOCIATED_PRODUCTS = 'CREATE_NON_ASSOCIATED_PRODUCTS'
export const SET_ERROR = 'SET_ERROR'
export const SET_FORM_DATA_COLLECTED = 'SET_FORM_DATA_COLLECTED'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
