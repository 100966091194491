<template>
  <div>
    <div class="header">
      <PrescriptionHeader
        :mobileTitle="$t('dispensing.delivery')"
        :isSecondaryText="isSecondaryText"
      />
    </div>
    <ExceptionAlerts v-if="!showExeptionAlerts" />
    <DispensingForm />
  </div>
</template>

<script>  
  import PrescriptionHeader from "@/components/general/PrescriptionHeader.vue";
  import DispensingForm from "@/components/dispensing/DispensingForm.vue";
  import ExceptionAlerts from "@/components/dispensing/ExceptionAlerts.vue";

  import { createNamespacedHelpers } from 'vuex'
  const prescriptionModule = createNamespacedHelpers('prescription');

  export default {
    data: () => ({
      isSecondaryText: true,
      showExeptionAlerts: false,
    }),
    components: {
      PrescriptionHeader,
      DispensingForm,
      ExceptionAlerts,
    },
    mounted () {
      this.showExeptionAlerts = this.providers.length && this.products.length;
    },
    computed: {
      ...prescriptionModule.mapGetters(['providers', 'products']),
    },
  }
</script>

<style lang="scss" scoped>
  .header {
    background-color: #f9f9f9 !important;
  }
</style>
