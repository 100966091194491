import { camelizeKeys } from 'humps';

import ehrApiService from '@/services/ehr-api.service';

import router from '@/router'

import {
  SET_PRESCRIPTION,
  SET_PRESCRIPTION_EXPOSURE_ID,
  SET_PRESCRIPTION_PRODUCTS,
  SET_PRESCRIPTION_PROVIDERS,
  SET_PRESCRIPTION_CURRENT_PROVIDER,
  SET_PRESCRIPTION_PROVIDER_DELIVERY_CITIES,
  SET_PRESCRIPTION_PROVIDER_PAYMENT_METHODS,
  SET_PRESCRIPTION_PROVIDER_FORMS,
  CREATE_PRESCRIPTION_DELIVERY_LOG,
  CREATE_PRESCRIPTION_DELIVERY_LOG_ERROR,
  SET_REQUESTED_ORDER_MESSAGE,
  CREATE_NON_ASSOCIATED_PRODUCTS,
  SET_ERROR,
  SET_FORM_DATA_COLLECTED,
  ADD_PRODUCT,
  REMOVE_PRODUCT
} from '../mutation-types';

const setPrescription = ({ commit }, payload) => {
  const {
    precriptionId,
    code,
    doctorCountryCode,
    countryName,
    prescriptionProducts,
    providers
  } = payload.data;

  const prescription = {
    precriptionId,
    code,
    doctorCountryCode,
    countryName,
  };

  prescriptionProducts.forEach(product => {
    product.maxQuantity = product.quantity;
  });

  sessionStorage.setItem('prescription', JSON.stringify(prescription));
  sessionStorage.setItem('products', JSON.stringify(prescriptionProducts));
  sessionStorage.setItem('providers', JSON.stringify(providers));

  commit(SET_PRESCRIPTION, prescription);
  commit(SET_PRESCRIPTION_PRODUCTS, prescriptionProducts);
  commit(SET_PRESCRIPTION_PROVIDERS, providers);
}

const setPrescriptionExposureId = ({ commit }, payload) => {
  sessionStorage.setItem('prescriptionExposureId', payload);
  commit(SET_PRESCRIPTION_EXPOSURE_ID, payload);
}

const setCurrentProvider = ({ commit, state }, payload) => {
  const { providers } = state;
  const cities = providers.filter(provider => provider.providerId === payload.providerId);
  const methods = providers.filter(provider => provider.providerId === payload.providerId);
  const forms = providers.filter(provider => provider.providerId === payload.providerId);

  commit(SET_PRESCRIPTION_CURRENT_PROVIDER, payload);
  commit(SET_PRESCRIPTION_PROVIDER_DELIVERY_CITIES, cities[0].deliveryCities);
  commit(SET_PRESCRIPTION_PROVIDER_PAYMENT_METHODS, methods[0].paymentMethods);
  commit(SET_PRESCRIPTION_PROVIDER_FORMS, forms[0].forms);
}

const createPrescriptionDelivery = ({ dispatch }, payload) => {
  dispatch('createPrescriptionDeliveryLog', payload)
  dispatch('setError',null)
}

const createPrescriptionDeliveryLog = async ({ commit, dispatch, state }, payload) => {
  try {
    const response = await ehrApiService.createPrescriptionDeliveryLog(payload);
    commit(CREATE_PRESCRIPTION_DELIVERY_LOG, camelizeKeys(payload));

    const status = response.status;
    const requestedOrderPath = '/requested-order';
    if(status === 201){
      let confirmationMessage = state.provider.confirmationMessage;
      
      if(response.data === ""){
        commit(SET_REQUESTED_ORDER_MESSAGE,{
          title:'requestedOrder.confirm',
          body:confirmationMessage,
          clickableLabel: null,
          url: null
        })
      }else{
        if(response.data.status === 'PARTIALLY_ASSOCIATED'){
          dispatch('createNonAssociatedProducts',response.data.non_associated_products)
          dispatch('setError','error.nonAssociatedProducts');
        }
        
        const customUrl = response.headers['location'];
        const clickableLabelMatches = confirmationMessage.match(/\{(.+?)\}/);
        let clickableLabel = '';
        if (clickableLabelMatches !== null) {
          clickableLabel = clickableLabelMatches[1];
          confirmationMessage = confirmationMessage.replace(/\{(.+?)\}/, "");
        }

        commit(SET_REQUESTED_ORDER_MESSAGE,{
          title:'requestedOrder.customUrlSuccessTitle',
          body:confirmationMessage,
          clickableLabel: clickableLabel,
          url: customUrl
        });
        
        window.open(customUrl, '_blank');
      }
      router.push(requestedOrderPath);
    }
    return response;    
  } catch (error) {
    const { response } = error;
    const status = response.status
    commit(CREATE_PRESCRIPTION_DELIVERY_LOG_ERROR, response ? response.data : error);
    
    if(status === 409){
      dispatch('setError','error.noProductsAvailable')
    }else {
      dispatch('setError','error.defaultError')
    }
  }
}

const updateProducts = ({commit},payload) =>{
  sessionStorage.setItem('products', JSON.stringify(payload));
  commit(SET_PRESCRIPTION_PRODUCTS,payload);
}

const createNonAssociatedProducts = ({commit},payload)=>{
  commit(CREATE_NON_ASSOCIATED_PRODUCTS,payload)
}

const setError = ({commit},payload)=>{
  commit(SET_ERROR,payload)
}

const setFormDataCollected = ({ commit, state }, payload) => {
  const { slug, event } = payload;
  const formDataCollected = state.formDataCollected;
  formDataCollected[slug] = event;
  commit(SET_FORM_DATA_COLLECTED, camelizeKeys(formDataCollected))
}

const addProduct = ({commit}, payload) => {
  commit(ADD_PRODUCT, payload)
}

const removeProduct = ({commit}, payload) => {
  commit(REMOVE_PRODUCT, payload)
}

export default {
  setPrescription,
  setPrescriptionExposureId,
  setCurrentProvider,
  createPrescriptionDelivery,
  createPrescriptionDeliveryLog,
  updateProducts,
  createNonAssociatedProducts,
  setError,
  setFormDataCollected,
  addProduct,
  removeProduct
}
